import { VariantCondition } from '../../types/navData.types';
import { Typography } from '../../foundations/Typography/Typography';
import { px2Rem } from '../../utils/px2Rem';
import { CSS_FONT_WEIGHT, TAILWIND_BREAKPOINTS } from '../../constants';
import { ColourSwatches } from '../ColourSwatches/ColourSwatches';
import { cn } from '../../utils/cn';
import Badge from '../Badge';
import SkeletonLoadingBlock from '../../designSystem/components/SkeletonLoadingBlock';
import { getImageProps as getNextImageProps } from 'next/image';
import {
  formatImageUrl,
  getProductCardImageSrcSet,
  getProductCardImageUrl,
  getReactImageProps,
  imgixLoader,
  PRODUCT_CARD_IMAGE_SIZES,
} from '../../utils/images';

type ProductCardProps = {
  cardDisplaySubtitle: string;
  colours: Array<`#${string}`>;
  displayName?: string | null;
  displayPrice?: string;
  imageUrl?: string;
  isPriorityImage?: boolean;
  make?: string | null;
  priceSuffix?: string;
  productVariantConditions: VariantCondition[];
  shouldDisplayPrices: boolean;
  variantSlug: string;
  /**
   * If rendering in Next.js, you should use `getImageProps` imported from `next/image`. If rending
   * elsewhere, you should use `getReactImageProps` from `uibook/utils/images`
   */
  getImageProps: typeof getNextImageProps | typeof getReactImageProps;
};

export const ProductCard = ({
  cardDisplaySubtitle,
  colours,
  displayName,
  displayPrice,
  imageUrl,
  isPriorityImage,
  make,
  priceSuffix,
  productVariantConditions,
  shouldDisplayPrices,
  variantSlug,
  getImageProps,
}: ProductCardProps) => {
  const imageSrc = formatImageUrl(imageUrl);

  const productCardImageSharedProps = {
    alt: displayName ?? '',
    loader: imgixLoader,
    priority: isPriorityImage,
    quality: 30,
  };

  /**
   * Use the `getImageProps` function to generate the props for the image tags. This can either be
   * the `getImageProps` from `next/image`, or the `getReactImageProps` function from
   * `uibook/utils/images`.
   *
   * @url https://nextjs.org/docs/pages/api-reference/components/image#getimageprops
   */
  const desktopImageProps = getImageProps({
    ...productCardImageSharedProps,
    width: PRODUCT_CARD_IMAGE_SIZES.desktop.width,
    height: PRODUCT_CARD_IMAGE_SIZES.desktop.height,
    src: getProductCardImageUrl({ src: imageSrc, device: 'desktop' }),
    sizes: `${PRODUCT_CARD_IMAGE_SIZES.desktop.width}px`,
  }).props;
  const mobileImageProps = getImageProps({
    ...productCardImageSharedProps,
    width: PRODUCT_CARD_IMAGE_SIZES.mobile.width,
    height: PRODUCT_CARD_IMAGE_SIZES.mobile.height,
    src: getProductCardImageUrl({ src: imageSrc, device: 'mobile' }),
    sizes: `${PRODUCT_CARD_IMAGE_SIZES.mobile.width}px`,
  }).props;

  /**
   * Use these srcSet values to override the `srcSet` values generated by `next/image`.
   *
   * `next/image` likes to create a wide range of `srcSet` values, but we only want to use the
   * initial width and double the width, as we know that the image will be displayed at that width
   * on the page. Mobile is only displayed at the `cardImageSizes.mobile.width` width, and desktop
   * is only displayed at the `cardImageSizes.desktop.width` width.
   *
   * This allows us to reduce the amount of HTML rendered for the PLP page, as we only generate the
   * exact srcSet values that are needed.
   */
  const mobileImageSrcSet = getProductCardImageSrcSet({
    src: imageSrc,
    device: 'mobile',
    quality: productCardImageSharedProps.quality,
  });
  const desktopImageSrcSet = getProductCardImageSrcSet({
    src: imageSrc,
    device: 'desktop',
    quality: productCardImageSharedProps.quality,
  });

  return (
    <div
      data-testid={`desktop-card-${variantSlug}`}
      className="shadow-card relative flex h-full w-full flex-col items-start justify-center bg-white py-4 pl-32 pr-3 lg:items-center lg:p-7"
    >
      <div className="pb-1 lg:pb-2">
        <Typography bold className="mr-1 inline lg:mr-0 lg:block lg:pb-2 lg:text-center">
          {make}
        </Typography>
        <Typography bold className="inline lg:block lg:text-center">
          {displayName}
        </Typography>
      </div>
      {cardDisplaySubtitle && (
        <Typography variant="body2" className="lg:text-center">
          {cardDisplaySubtitle}
        </Typography>
      )}

      <div className="flex h-full flex-col justify-end">
        <div
          className="absolute left-4 top-1/2 flex h-[var(--img-h)] w-[var(--img-w)] -translate-y-1/2 transform justify-center lg:relative lg:left-auto lg:top-auto lg:mb-3 lg:mt-6 lg:h-[var(--lg-img-h)] lg:w-[var(--lg-img-w)] lg:transform-none lg:justify-center"
          style={{
            '--img-w': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.mobile.width)}`,
            '--img-h': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.mobile.height)}`,
            '--lg-img-w': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.desktop.width)}`,
            '--lg-img-h': `${px2Rem(PRODUCT_CARD_IMAGE_SIZES.desktop.height)}`,
          }}
        >
          <picture>
            <source
              media={`(min-width: ${TAILWIND_BREAKPOINTS.lg}px)`}
              srcSet={desktopImageSrcSet}
              sizes={desktopImageProps.sizes}
            />
            <img
              {...mobileImageProps}
              srcSet={mobileImageSrcSet}
              alt={productCardImageSharedProps.alt}
              style={{ width: '100%', height: 'auto' }}
            />
          </picture>
        </div>

        {colours.length > 1 ? (
          <ColourSwatches
            dataTestId="colour-swatches"
            colours={colours}
            className="items-start justify-start gap-1.5 pb-3 pt-2 lg:justify-center lg:pb-4 lg:pt-3"
          />
        ) : null}

        <div
          className={cn('flex justify-start gap-2 pb-3 lg:justify-center lg:pb-4', {
            'pt-8 lg:pt-10': colours.length < 2,
          })}
        >
          {[
            {
              condition: VariantCondition.New,
              label: 'Brand New',
              dataTestId: 'condition-badge-new',
            },
            {
              condition: VariantCondition.Refurbished,
              label: 'Refurbished',
              dataTestId: 'condition-badge-refurbished',
            },
          ]
            .filter((item) => productVariantConditions.includes(item.condition))
            .map((item) => (
              <Badge
                key={item.condition}
                type="rounded"
                fontWeight={CSS_FONT_WEIGHT.vars.regular}
                color="lightGrey"
                fontSize={12}
                textTransform="capitalize"
                dataTestId={item.dataTestId}
              >
                {item.label}
              </Badge>
            ))}
        </div>

        {shouldDisplayPrices ? (
          <Typography variant="body2" bold className="lg:type-h4 lg:text-center">
            <span className="text-charcoal-400 lg:type-body2 font-normal">From</span> £
            {displayPrice}/mo{' '}
            {priceSuffix && (
              <span className="text-charcoal-400 lg:type-body2 font-normal">{priceSuffix}</span>
            )}
          </Typography>
        ) : (
          <SkeletonLoadingBlock
            dataTestId={`${variantSlug}-loading`}
            $height={18}
            $borderRadius={5}
          />
        )}
      </div>
    </div>
  );
};
